import React from "react";
import Helmet from "react-helmet";
import { get } from 'lodash';
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../blogComponents/PostListing/PostListing";
import PostTags from "../blogComponents/PostTags/PostTags";
import config from "../../data/SiteConfig";
import './tag.css';

export default class TagTemplate extends React.Component {
  render() {
    const { tag } = this.props.pageContext;
    const allTagsList = Array.from(this.props.data.tagsList.edges.reduce((set, curr) => {
      get(curr, 'node.frontmatter.tags', []).forEach(tagItem => {
        if (tagItem !== tag) {
          set.add(tagItem);
        }
      });
      return set;
    }, new Set()).keys());
    const postEdges = this.props.data.main.edges;

    return (
      <Layout>
        <div className="tag-header">
          <div className="tag-header-label">
            <span className='active-tag-highlight'>Posts Matching Tag:</span>
            <span className='active-tag'>{tag}</span>
          </div>
        </div>
        <div className="tag-container">
          <Helmet title={`${config.siteTitle} | Posts tagged as "${tag}"`} />
          <PostListing postEdges={postEdges} />
        </div>
        <PostTags prependLabel='Suggested Other ' tags={allTagsList} />
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    tagsList: allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }

    main: allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;

import React, {Fragment, Component} from "react";
import { Link } from "gatsby";
import './index.css';

export default class PostListing extends Component {
  getPostList() {
    return this.props.postEdges.map(postEdge => ({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead
    }));
  }
  render() {
    const postList = this.getPostList();
    return (
      <Fragment>
        <div className="post-list-container">
          {/* Your post list here. */
          postList.map(post => (
            <Link
              className="post-link"
              to={post.path}
              key={post.title}
            >
              <div
                className="post-image"
                style={{
                  backgroundImage: `url(${post.cover})`
                }}
              >
                <img
                  className="post-image-seo"
                  alt={`Afford NYC: ${post.title}`}
                  src={post.cover}
                />
              </div>
              <h4
                className="post-title"
              >
                {post.title}
              </h4>
            </Link>
          ))}
        </div>
      </Fragment>
    );
  }
}

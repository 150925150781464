import React, { Component } from "react";
import {kebabCase} from "lodash";
import { Link } from "gatsby";
import './index.css';

export default class PostTags extends Component {
  render() {
    return (
      <div className="post-tag-container">
        <span className='post-tag-label'>
          {this.props.prependLabel || ''}Tags:
        </span>
        {
          (this.props.tags || []).map(tag => (
            <Link
              key={tag}
              style={{ textDecoration: "none" }}
              to={`/tags/${kebabCase(tag)}`}
            >
              <button className='tag-button'>{tag}</button>
            </Link>
          ))
        }
      </div>
    );
  }
}
